import React, { useEffect, useRef, useState } from "react";
import DownIcon from "../../../assets/icons/down-icon.svg";
import Calender from "./calender";
import { useDispatch, useSelector } from "react-redux";
import { userSpendingData } from "../../../redux/features/subscriptionSlice";
import { useNavigate } from "react-router";
const Overview = ({ spendDays, setSpendDays, spendType, setSpendType }) => {
  const dropdownRef = useRef();
  const userData = JSON.parse(localStorage.getItem("usersData"));
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const { userSpendData } = useSelector((state) => ({
    ...state.subscription,
  }));

  const handleChangeDays = (e) => {
    setSpendDays(parseInt(e.target.value));
    dispatch(userSpendingData({ days: parseInt(e.target.value), spend_type: spendType }));
  };

  const handleChangeFilter = (data) => {
    setSpendType(data);
    dispatch(userSpendingData({ days: spendDays, spend_type: data }));
    setDropdown(false);
  };

  useEffect(() => {
    dispatch(userSpendingData({ days: spendDays, spend_type: spendType }));
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdown]);

  return (
    <div>
      <div className="py-6 px-4 ">
        {/* <div className='flex items-center gap-2 pb-2'>
            <p className='text-sm font-semibold text-black300 m-0'>
            Projected Spending
            </p>
            <img className='block cursor-pointer' src={DownIcon} alt='DownIcon'/>
        </div> */}
        <div className="relative">
          <div className="flex">
            <div onClick={() => setDropdown(!dropdown)} ref={dropdownRef} className="w-[160px] cursor-pointer bg-white pr-2 text-sm flex items-center justify-between font-medium text-black300 rounded">
              <p className="text-sm font-semibold text-black300 m-0">{spendType === "Projected" ? "Projected Spending" : "Past Spending"}</p>
              <i class="fa-solid fa-chevron-down text-black300 cursor-pointer"></i>
            </div>
            <div className="flex items-center justify-center">
              <select className="text-xs text-[10px] font-normal text-gray600 px-2 w-[85px] border border-solid border-borderColor3 rounded h-6 focus:outline-none" onChange={(e) => handleChangeDays(e)}>
                <option value={30}>30 Days</option>
                <option value={60}>60 Days</option>
                <option value={180}>6 Months</option>
              </select>
            </div>
          </div>
          <div className={dropdown ? "cus-dropdown-design show" : "cus-dropdown-design hide"}>
            <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter("past")}>
              Past Spending
            </span>
            <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter("Projected")}>
              Projected Spending
            </span>

            {/* <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover">Last 2 month</span>
            <span className="block text-sm p-2 font-medium text-black300  cursor-pointer transition ease-in-out hover:bg-bgHover ">Last 4 month</span> */}
          </div>
        </div>
        {/* <div>
          <select className="text-xs text-[10px] font-normal text-gray600 px-2 w-[120px] border border-solid border-borderColor3 rounded h-6 focus:outline-none" onChange={(e) => handleChangeDays(e)}>
            <option value={30}>30 Days</option>
            <option value={60}>60 Days</option>
            <option value={180}>6 Months</option>
          </select>
        </div> */}
        <div className="pt-2 ">
          <p className="text-[30px] leading-[40px] text-black300 font-bold">
            {userData?.currency ?? "$"}
            {userSpendData ?? "0.00"}
          </p>
            <p className="text-xs font-normal text-black300 underline cursor-pointer" onClick={() => navigate(spendType === "Projected" ? "/dashboard/recurring" : "/dashboard/transactions")}>
              See all Transactions
            </p>
        </div>
        {/* <Calender/> */}
      </div>
    </div>
  );
};

export default Overview;
