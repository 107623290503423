import React, { useEffect, useState } from "react";

// import { useRouter } from "next/router";
import _ from "lodash";
import { useAuth } from "../../context/authContext.js";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/logo.svg";
import OTPInput from "otp-input-react";
import EyeShow from "../../assets/images/eyeShow.png";
import EyeHidden from "../../assets/images/eyeHidden.png";
import { message } from "antd";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowInput, setIsShowInput] = useState("");
  const [otpValue, setOtpValue] = useState();
  const [errMessages, setErrMessages] = useState({
    email: "",
    password: "",
  });
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);

  const { sendOTP, verifyOTP, savePassword } = useAuth();

  const handleSubmit = async () => {
    if (validate(inputValue)) {
      setIsLoading(true);
      try {
        let res = await sendOTP({ email: inputValue?.email });
        if (res?.data?.success) {
          setIsLoading(false);
          setIsShowInput("forOTP");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("errorrrrr", error);
      }
    }
  };

  const handleVerifyOTP = async () => {
    if (validate(otpValue)) {
      setIsLoading(true);
      try {
        let res = await verifyOTP({ email: inputValue?.email, otp: otpValue });
        if (res?.data?.success) {
          setIsLoading(false);
          setIsShowInput("forPassword");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("errorrrrr", error);
      }
    }
  };

  const handleSavePassword = async () => {
    if (validate(inputValue)) {
      setIsLoading(true);
      try {
        let res = await savePassword(inputValue);
        if (res?.data?.success) {
          navigate("/login");
          setIsLoading(false);
          setIsShowInput("");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("errorrrrr", error);
      }
    }
  };

  const validate = (values) => {
    let errors = {};
    let isValid = true;
    const emailreRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

    if (isShowInput === "forOTP") {
      if (!otpValue) {
        errors.otp = "Please enter valid OTP";
        isValid = false;
      } else if (otpValue.length < 6) {
        errors.otp = "Please enter a valid OTP";
        isValid = false;
      }
    } else if (isShowInput === "forPassword") {
      if (!values.password) {
        errors.password = "Please enter your password!";
        isValid = false;
      } else if (!PASSWORD_REGEX.test(values.password)) {
        errors.password =
          "Passwords must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.";
        isValid = false;
      }
    } else {
      if (!values.email) {
        errors.email = "Please enter your email";
        isValid = false;
      } else if (values.email && !emailreRegex.test(values.email)) {
        errors.email = "Please enter a valid email";
        isValid = false;
      }
    }
    setErrMessages(errors);
    return isValid;
  };

  const handleChange = (e) => {
    if (e.target.value.trim() !== "") {
      setInputValue({
        ...inputValue,
        [e.target.name]: e.target.name === "email" ? e?.target?.value?.trim()?.toLowerCase() : e?.target?.value?.trim(),
      });
      setErrMessages({ ...errMessages, [e.target.name]: "" });
    } else {
      setInputValue({ ...inputValue, [e.target.name]: "" });
    }
  };

  const handleOTPChange = (otp) => {
    setOtpValue(otp);
    setErrMessages({ ...errMessages, otp: "" });
  };

  const handleResendOTP = async () => {
    setIsLoading(true);
    setOtpValue();
    try {
      let res = await sendOTP({ email: inputValue?.email });
      if (res?.data?.success) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("errorrrrr", error);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "";
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (isShowInput === "forPassword") {
        handleSavePassword();
      } else if (isShowInput === "forOTP") {
        handleVerifyOTP();
      } else {
        handleSubmit();
      }
    }
  };

  return (
    <>
      <div className="h-screen bg-greenLight tab:h-auto">
        <div className="p-14 h-full mobile:py-0 mobile:px-0 mobile:h-auto">
          <div className="max-w-1340 h-full mx-auto px-5 mobile:px-0">
            <div className="bg-primary h-full rounded-[20px] mobile:h-auto mobile:pb-60 mobile:rounded-none">
              <div className="grid h-full grid-cols-2 tab:grid-cols-1 gap-100 mobile:gap-0 mobile:pt-60">
                <div className="h-full p-16 mobile:p-5 flex items-center">
                  <div>
                    <div className="pb-16 mobile:pb-10 mobile:flex mobile:items-center mobile:justify-center">
                      <NavLink to="/">
                        <img className="cursor-pointer w-[320px] mobile:w-auto" src={Logo} alt="Logo" />
                      </NavLink>
                    </div>
                    <h2 className="text-[50px] mobile:text-[32px] mobile:text-center mobile:mb-8 font-normal text-black300 mb-60">
                      Simplify Your <span className="font-extrabold">Subscriptions</span>, Amplify Your Savings
                    </h2>
                  </div>
                </div>
                <div className="h-full bg-white rounded-[20px] flex items-center mobile:p-5 p-10 mobile:m-5">
                  <div className="w-full">
                    <h2 className="text-[30px] mb-10 text-black300 font-bold tracking-[1.4px]">Forgot Password?</h2>
                    {isShowInput === "" ? (
                      <div className="pb-5">
                        <label className="block text-base text-gray600 pb-4">
                          Email<span className="text-redError">*</span>
                        </label>
                        <input
                          className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={inputValue?.email}
                          onChange={(e) => handleChange(e)}
                          onKeyPress={handleKeyPress}
                        />
                        <span className="text-redError text-sm">{errMessages?.email}</span>
                      </div>
                    ) : isShowInput === "forPassword" ? (
                      <>
                        <div className="pb-5">
                          <label className="block text-base text-gray600 pb-4">
                            Password<span className="text-redError">*</span> 
                          </label>
                          <div className="relative flex items-center">
                            <input
                              className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent flex"
                              type={passwordShow ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              value={inputValue?.password}
                              onChange={(e) => handleChange(e)}
                              style={{ padding: "0 40px 0 10px" }}
                              onKeyPress={handleKeyPress}
                            />
                            {passwordShow ? (
                              <img
                                className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                src={EyeShow}
                                alt="EyeShow"
                                width={20}
                                height={20}
                                onClick={() => setPasswordShow(!passwordShow)}
                              />
                            ) : (
                              <img
                                className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                src={EyeHidden}
                                alt="EyeHidden"
                                width={20}
                                height={20}
                                onClick={() => setPasswordShow(!passwordShow)}
                              />
                            )}
                          </div>
                          <span className="text-redError text-sm">{errMessages?.password}</span>
                        </div>
                      </>
                    ) : isShowInput === "forOTP" ? (
                      <div className="pb-3">
                        <label className="block text-base text-gray600 pb-4">
                          OTP<span className="text-redError">*</span> <span className="text-redError text-sm">{errMessages?.otp}</span>
                        </label>
                        <OTPInput
                          value={otpValue}
                          onChange={handleOTPChange}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          className="custom-otp-input "
                        />
                          <span className="text-base text-right block font-medium text-link pt-3 cursor-pointer" onClick={() => handleResendOTP()}>
                            Resend OTP
                          </span>
                      </div>  
                    ) : (
                      <></>
                    )}

                    <div className="flex items-center justify-center pt-3">
                      {isShowInput === "forPassword" ? (
                        <button
                          disabled={isLoading}
                          className="flex justify-center gap-2.5 p-3 px-9 rounded-full text-base font-semibold bg-primary w-full"
                          onClick={() => handleSavePassword()}
                        >
                          Save {isLoading && <div className="loader"></div>}
                        </button>
                      ) : isShowInput === "forOTP" ? (
                        <button
                          disabled={isLoading}
                          className="flex justify-center gap-2.5 p-3 px-9 rounded-full text-base font-semibold bg-primary w-full"
                          onClick={() => handleVerifyOTP()}
                        >
                          Verify OTP {isLoading && <div className="loader"></div>}
                        </button>
                      ) : (
                        <button
                          disabled={isLoading}
                          className="flex justify-center gap-2.5 p-3 px-9 rounded-full text-base font-semibold bg-primary w-full"
                          onClick={() => handleSubmit()}
                        >
                          Send OTP {isLoading && <div className="loader"></div>}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
