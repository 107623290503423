"use client";
import React, { useState, useEffect } from "react";
// import Link from "next/link";
// import { useRouter } from "next/navigation";
import axios from "axios";
import styles from "./signup.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useAuth } from "../../context/authContext";
import AppConstants from "../../constants/AppConstants";
import Logo from "../../assets/logo/logo.svg";
import GoogleIcon from "@mui/icons-material/Google";
import EyeShow from "../../assets/images/eyeShow.png";
import EyeHidden from "../../assets/images/eyeHidden.png";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: "",
  });
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState({});
  const navigate = useNavigate();
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const { signupUser, loginWithGoogleAPI } = useAuth();

  const handleclick = async () => {
    setIsLoadingGoogle(true);
    // setTimeout(() => {
    //   setIsLoadingGoogle(false);
    // }, 2000);
    const { loginWithGoogle } = await import("../login/firebase");
    try {
      const res = await loginWithGoogle();
      if (res?.user) {
        let response = await loginWithGoogleAPI({ accessToken: res?.user?.stsTokenManager?.accessToken });
        if (response?.code) {
          if (response?.status === 200) {
            // message.success("Welcome to Dashboard");
            message.success("Login successfully!");
            setIsLoadingGoogle(false);
            navigate("/dashboard");
            message.error(response?.message);
          }
        } else {
          setIsLoadingGoogle(false);
        }
      } else {
        setIsLoadingGoogle(false);
      }
    } catch (err) {
      console.log("google", err);
      setIsLoadingGoogle(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const validPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return validPasswordRegex.test(password); // Placeholder, replace with actual validation
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      const isValidEmail = validateEmail(value);
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        email: isValidEmail ? "" : "Please enter a valid email address",
      }));
    }

    if (name === "password") {
      const isValidPassword = validatePassword(value);
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        password: isValidPassword ? "" : "Password should contain special characters and alphapets",
      }));
    }
  };

  const handleSignup = async () => {
    if (ValidateData(inputValue)) {
      setIsLoading(true);
      let datatoSend = {};
      datatoSend.name = inputValue.name?.trim();
      datatoSend.email = inputValue.email;
      datatoSend.password = inputValue.password;
      let res = await signupUser(datatoSend);
      if (res) {
        setIsLoading(false);
        setInputValue({});
      } else {
        setIsLoading(false);
      }
    }
  };

  const ValidateData = (values) => {
    let errors = {};
    let isValid = true;

    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    
    // const emailreRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    if (!values?.name) {
      errors.name = "Please enter your full name.";
      isValid = false;
    }

    if (!values.email) {
      errors.email = "Please enter your email";
      isValid = false;
    } else if (values.email && !emailRegex.test(values.email)) {
      errors.email = "Please enter a valid email";
      isValid = false;
    } else if (values.email.includes(";")) {
      errors.email = "Emails cannot contain semicolons.";
      isValid = false;
    }


    if (!values.password) {
      errors.password = "Please enter your password!";
      isValid = false;
    } else if (!PASSWORD_REGEX.test(values.password)) {
      errors.password = "Passwords must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.";
      isValid = false;
    }
    setErrMessages(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();
    if (name === "name") {
      if (value !== trimmedValue) {
        setInputValue({ ...inputValue, [name]: trimmedValue });
      } else {
        setInputValue({ ...inputValue, [name]: value });
        setErrMessages({ ...errMessages, [name]: "" });
      }
    } else {
      if (e.target.value.trim() !== "") {
        setInputValue({
          ...inputValue,
          [e.target.name]: e.target.name === "email" ? e?.target?.value?.trim()?.toLowerCase() : e?.target?.value?.trim(),
        });
        setErrMessages({ ...errMessages, [e.target.name]: "" });
      } else {
        setInputValue({ ...inputValue, [e.target.name]: "" });
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = "";
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSignup();
    }
  };

  return (
    <>
      <div className="h-screen bg-greenLight tab:h-auto">
        <div className="p-14 h-full mobile:py-0 mobile:px-0 ">
          <div className="max-w-1340 h-full mx-auto px-5 mobile:px-0">
            <div className="bg-primary h-full rounded-[20px] mobile:h-auto mobile:pb-60 mobile:rounded-none">
              <div className="grid h-full grid-cols-2 tab:grid-cols-1 gap-100 mobile:gap-0 mobile:pt-60">
                <div className="h-full p-16 mobile:p-5 flex items-center">
                  <div>
                    <div className="pb-16 mobile:pb-10 mobile:flex mobile:items-center mobile:justify-center">
                      <NavLink to="/">
                        <img className="cursor-pointer w-[320px] mobile:w-auto" src={Logo} alt="Logo" />
                      </NavLink>
                    </div>
                    <h2 className="text-[50px] mobile:text-center mobile:text-[32px] mobile:mb-8 font-normal text-black300 mb-60">
                      Simplify Your <span className="font-extrabold">Subscriptions</span>, Amplify Your Savings
                    </h2>
                  </div>
                </div>
                <div className="h-full bg-white rounded-[20px] flex items-center mobile:p-5 p-10 mobile:m-5 overflow-auto" style={{ scrollbarWidth: "none" }}>
                  <div className="w-full">
                    <h2 className="text-[30px] mb-10 text-black300 font-bold tracking-[1.4px]">Create Account</h2>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Full Name<span className="text-redError">*</span>
                      </label>
                      <input
                        className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={inputValue?.name}
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="text-redError text-sm">{errMessages?.name}</span>
                    </div>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Email<span className="text-redError">*</span>
                      </label>
                      <input
                        className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="text-redError text-sm">{errMessages?.email}</span>
                    </div>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Password<span className="text-redError">*</span>
                      </label>
                      <div className="relative flex items-center">
                        <input
                          className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                          type={passwordShow ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={inputValue?.password}
                          onChange={(e) => handleChange(e)}
                          onKeyPress={handleKeyPress}
                        />
                        {passwordShow ? (
                          <img
                            className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            src={EyeShow}
                            alt="EyeShow"
                            width={20}
                            height={20}
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        ) : (
                          <img
                            className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            src={EyeHidden}
                            alt="EyeHidden"
                            width={20}
                            height={20}
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        )}
                      </div>
                      <span className="text-redError text-sm">{errMessages?.password}</span>
                    </div>
                    <div className="flex items-center justify-center pt-4 pb-3">
                      <button disabled={isLoading || isLoadingGoogle} className="flex justify-center gap-2.5 p-3 px-9 rounded-full text-base font-semibold bg-primary w-full" onClick={() => handleSignup()}>
                        Create Account {isLoading && <div className="loader"></div>}
                      </button>
                    </div>
                    <p className="text-base font-medium text-gray600 mb-3 text-center">OR</p>
                    <button disabled={isLoadingGoogle || isLoading} className=" p-3 px-9 flex items-center gap-3 justify-center rounded-full text-base font-semibold bg-white border border-solid border-primary w-full" onClick={handleclick}>
                      <GoogleIcon style={{ color: "#d0ee11" }} />
                      Signup with Google {isLoadingGoogle && <div className="loader"></div>}
                    </button>
                    <p className="text-base pt-5 font-medium text-black300 text-center">
                      Already have an account?{" "}
                      <Link to="/login">
                        <a className="text-link">Login</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signup;
