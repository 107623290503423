import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import NextArrow from "../../../assets/icons/right-arrow.svg";
import PreviousArrow from "../../../assets/icons/previous-arrow.svg";
import NoDataFound from "../../../assets/images/dashboard-no-data.webp";
import { useDispatch, useSelector } from "react-redux";
import { activityStreamGraph, averageCoats, GetALLSubscriptions, getCurrentAllSubscriptions, GetUserCurrentSubscriptions, handleSubscriptionStatus, UpdateSubscription, userSpendingData } from "../../../redux/features/subscriptionSlice";
import SubscriptionSlider from "./subscriptionSlider";
import { useNavigate } from "react-router";
import { useAuth } from "../../../context/authContext";
import SubscriptiondetailsModal from "../../../components/subscriptiondetailsModal";
import { message, Spin } from "antd";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "./subscriptionslider.scss";
import FullPageLoader from "../../../components/common/loaders/FullPageLoader";
import SubscriptionStatusModel from "../../../components/subscriptionStatusModel";

function SampleNextArrow(props) {
  const { className, style, onClick, isLastSlide } = props;
  return (
    <div className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer bg-white absolute right-0 top-[50%] translate-y-[-50%] z-30 shadow-3xl" style={{ ...style, display: isLastSlide ? "none" : "" }} onClick={onClick}>
      <img src={NextArrow} alt="NextArrow" className="w-[16px]" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, isFirstSlide } = props;
  return (
    <div className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer bg-white absolute left-0 top-[50%] translate-y-[-50%] z-30 shadow-3xl" style={{ ...style, display: isFirstSlide ? "none" : "" }} onClick={onClick}>
      <img src={PreviousArrow} alt="PreviousArrow" className="w-[16px]" />
    </div>
  );
}

const CurrentSubscription = ({ spendDays, spendType, selectedName, isCustomDate }) => {
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { currentAllSubs, loading, currentAllSubsLoader, updateSubscLoader } = useSelector((state) => state.subscription);
  const { allSubscriptions } = useSelector((state) => state.subscription);
  const [activeTab, setActiveTab] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: "",
    sub_description: "",
    cost: "",
    plan_name: "monthly",
    start_date: "",
    end_date: "",
    notes: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: "",
    active: null,
  });

  const [activeModel, setActiveModel] = useState({
    isShow: false,
    id: null,
    isActive: false,
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  const handleChangeSubscriptionStatus = () => {
    let forInactiveData = { id: activeModel?.id, body: { active: activeModel?.isActive } };
    let forActiveData = { id: activeModel?.id, body: { active: activeModel?.isActive, start_date: activeModel?.start_date, end_date: activeModel?.end_date } };
    dispatch(handleSubscriptionStatus(activeModel?.isActive ? forActiveData : forInactiveData))
      .then((res) => {
        message.success(res?.payload);
        dispatch(getCurrentAllSubscriptions({ status: activeTab }));
        setActiveModel({ isShow: false, id: null, isActive: false });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    dispatch(getCurrentAllSubscriptions({ status: activeTab }));
    dispatch(GetALLSubscriptions());
    dispatch(GetUserCurrentSubscriptions({ status: activeTab }));
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = currentAllSubs?.length;

  const settings = {
    // className: "slider variable-width",
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow isLastSlide={currentSlide === totalSlides - 1} />,
    prevArrow: <SamplePrevArrow isFirstSlide={currentSlide === 0} />,
    variableWidth: true,
    afterChange: (index) => setCurrentSlide(index),
  };

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();

    let data = { id: newSubsData?.id, body: newSubsData };
    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }
    dispatch(UpdateSubscription(data))
      .then((res) => {
        message.success(res?.message || "Subscription Updated Successfully");
        dispatch(getCurrentAllSubscriptions({ status: activeTab }));
        dispatch(userSpendingData({ days: spendDays, spend_type: spendType }));
        dispatch(GetUserCurrentSubscriptions());
        dispatch(averageCoats());
        if (selectedName === "Custom Date") {
          dispatch(
            activityStreamGraph({
              startDate: isCustomDate?.finalStartDate,
              endDate: isCustomDate?.finalEndDate,
            })
          );
        } else {
          dispatch(activityStreamGraph({ days: selectedName === "3 Months" ? 90 : selectedName === "6 Months" ? 180 : 90 }));
        }
      })
      .catch((err) => {
        message.error(err);
      });
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      end_date: "",
      notes: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
      sub_id: null,
      active: null,
    });
    handleClose();
  };

  const handleChangeTab = (data) => {
    setActiveTab(data);
    dispatch(getCurrentAllSubscriptions({ status: data }));
  };

  const handleSubsDetails = (e) => {
    if (e.target.name === "email") {
      if (e.target.value.trim() !== "") {
        setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
      } else {
        setNewSubsData({ ...newSubsData, [e.target.name]: "" });
        setError({ ...error, [e.target.name]: "Please enter email" });
      }
    } else {
      setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value?.trimStart() });
      setError({ ...error, [e.target.name]: "" });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModel = (item) => {
    setOpen(true);
    setNewSubsData({
      ...newSubsData,
      active: item.active,
      sub_id: item.sub_id,
      sub_title: item?.sub_id,
      free_trial_days: item.free_trial_days,
      cost: item?.cost,
      id: item?.id,
      sub_type: item?.type,
      start_date: item?.start_date,
      end_date: item?.end_date,
      notes: item?.notes,
      isPaymentMethod: item?.isPaymentMethod,
      card_no: item?.card_no,
      plan_variety: item?.plan_variety,
      plan_name: item?.plan_name,
      pay_method: item?.pay_method,
      paypal_email: item?.paypal_email ?? "",
      name: item?.name,
      email: item?.email,
    });
  };

  function InlineWrapperWithMargin({ children }) {
    return <span style={{ marginRight: "0.5rem" }}>{children}</span>;
  }

  // const modelRef = useRef();
  // useEffect(() => {
  //   console.log("aaaaaaaaaaaaaaaaaaa")
  //   const checkIfClickedOutside = (e) => {
  //     if (open && modelRef.current && !modelRef.current.contains(e.target)) {
  //       handleClose();
  //     }
  //   };
  //   document.addEventListener("mousedown", checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [open, modelRef]);

  return (
    <div className="SubscriptionSlider-page-alignment">
      <div className="flex items-center justify-between pb-2 mobile:block">
        <div className="flex items-center gap-3 ">
          <p className="text-xl text-black300 mobile:text-base font-medium m-0">Current Subscriptions |</p>
          <div className="flex items-center gap-3">
            <div className="w-8 min-w-8 h-5 text-sm border border-solid border-borderColor1 flex items-center justify-center font-medium rounded-md">
              <span className="relative top-[1px]">{currentAllSubs?.length}</span>
            </div>
            <p className="text-base mobile:text-sm text-gray700 font-normal">{activeTab ? "Active" : "Inactive"} subscripitons</p>
          </div>
        </div>
        <p className="text-gray700 text-base font-medium cursor-pointer mobile:mt-2" onClick={() => navigate("/dashboard/subscription-list")}>
          + Add Subscription
        </p>
      </div>
      <div className="flex items-center gap-9 pb-2 border-b border-solid border-borderColor1">
        <div className="cursor-pointer" onClick={() => handleChangeTab(true)}>
          <span className={`text-sm custom-tab relative font-medium text-black300 ${activeTab ? "active-custom-tab" : ""}`}>Active</span>
        </div>
        <div className="cursor-pointer" onClick={() => handleChangeTab(false)}>
          <span className={`text-sm custom-tab   relative font-medium text-black300 ${!activeTab ? "active-custom-tab" : ""}`}>Inactive</span>
        </div>
      </div>
      <div className="mt-5 mobile:mt-3 mobile:max-h-[315px] mobile:overflow-auto mobile:shadow-lg">
        {currentAllSubsLoader ? (
          <>
            <div className="flex gap-5 flex-wrap mobile:grid mobile:grid-cols-1 mobile:gap-2">
              {currentAllSubs?.map((item, index) => {
                return (
                  <div className="min-w-[220px] w-[220px] mobile:w-full mobile:min-w-full">
                    <SubscriptionSlider item={item} handleOpenModel={handleOpenModel} activeModel={activeModel} setActiveModel={setActiveModel} />
                  </div>
                );
              })}
            </div>
          </>
        ) : currentAllSubs?.length ? (
          currentAllSubs?.length > 5 ? (
            <Slider {...settings}>
              {currentAllSubs.map((item, index) => {
                return (
                  <div className="min-w-[220px] max-w-[220px] w-[220px] mobile:w-full mobile:max-w-[100%] mobile:min-w-full">
                    <SubscriptionSlider item={item} handleOpenModel={handleOpenModel} activeModel={activeModel} setActiveModel={setActiveModel} />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="flex gap-5 flex-wrap mobile:grid mobile:grid-cols-1 mobile:gap-2">
              {currentAllSubs?.map((item, index) => {
                return (
                  <div className="min-w-[220px] w-[220px] mobile:w-full mobile:min-w-full">
                    <SubscriptionSlider item={item} handleOpenModel={handleOpenModel} activeModel={activeModel} setActiveModel={setActiveModel} />
                  </div>
                );
              })}
            </div>
          )
        ) : (
          <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center  p-6 rounded-[20px] w-full">
            <div className="flex items-center justify-center">
            <img src={NoDataFound} alt="No Data Found" width={140} height={140} />
            </div>
            <p className="text-xl font-medium text-black mt-2">No subscription found</p>
            <p>You have not added any subscripitons yet</p>
          </div>
        )}
      </div>

      {/* <div className="pt-5">
        <Skeleton style={{ display: "flex", gap: "10px" }} width={100} count={5} />
      </div> */}

      {activeModel?.isShow && <SubscriptionStatusModel activeModel={activeModel} setActiveModel={setActiveModel} handleChangeSubscriptionStatus={handleChangeSubscriptionStatus} />}

      {/* {updateSubscLoader && <FullPageLoader />} */}

      {open && (
        <SubscriptiondetailsModal
          open={open}
          userData={userData}
          setOpen={setOpen}
          handleClose={handleClose}
          handleSubsDetails={handleSubsDetails}
          setNewSubsData={setNewSubsData}
          newSubsData={newSubsData}
          handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          error={error}
          setError={setError}
          allSubscriptions={allSubscriptions}
          setIsEditSubscription={true}
          activeTab={activeTab}
        />
      )}
    </div>
  );
};

export default CurrentSubscription;
