import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { GetALLSubscriptions, GetUserCurrentSubscriptions, handleSubscriptionStatus, upcomingSubscription, UpdateSubscription } from "../../../redux/features/subscriptionSlice";
import moment from "moment";
import SubscriptionStatusModel from "../../subscriptionStatusModel";
import { message, Skeleton } from "antd";
import NoDataFound from "../../../assets/images/My-Subscription-no-data.webp";
import DeleteTransactionModel from "../../deleteTransactionModel";
import SubscriptiondetailsModal from "../../subscriptiondetailsModal";
import { useAuth } from "../../../context/authContext";
import FullPageLoader from "../../common/loaders/FullPageLoader";

const UpcommingTable = () => {
  const dropdownRef = useRef();
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const usersData = JSON.parse(localStorage.getItem("usersData"));
  const { upcomingSubsData, upcomingSubsLoader, usersCurrentSub, allSubscriptions, updateSubscLoader } = useSelector((state) => state.subscription);
  const [dropdown, setDropdown] = useState(false);
  const [selectedName, setSelectedName] = useState("7 Days");
  const [selectedDays, setSelectedDays] = useState({
    isCustom: false,
    days: 7,
    startDate: null,
    endDate: null,
  });
  const [isCutomDate, setIsCostumDate] = useState({
    isShowCalender: false,
    startDate: null,
    endDate: null,
  });
  const [activeModel, setActiveModel] = useState({
    isShow: false,
    id: null,
    isActive: false,
    start_date: "",
    end_date: "",
  });

  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [isEditSubscription, setIsEditSubscription] = useState(false);
  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: "",
    sub_description: "",
    cost: "",
    plan_name: "monthly",
    start_date: "",
    end_date: "",
    notes: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: "",
  });

  const handleChangeFilter = (days, name) => {
    if (name === "custom" && days === 0) {
      setSelectedName("Custom Date");
      setIsCostumDate({ ...isCutomDate, isShowCalender: true });
      setSelectedDays({
        isCustom: true,
        days: 0,
      });
    } else {
      setSelectedName(name);
      setIsCostumDate({ ...isCutomDate, isShowCalender: false, startDate: null, endDate: null });
      dispatch(upcomingSubscription({ type: days }));
      setSelectedDays({
        isCustom: false,
        days: days,
      });
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setIsCostumDate({ ...isCutomDate, startDate: start, endDate: end });
    setSelectedDays({ ...selectedDays, startDate: start, endDate: end });
    if (start && end) {
      dispatch(upcomingSubscription({ startDate: start, endDate: end }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditSubscription(false);
    setError({});
  };

  const handleChangeSubscriptionStatus = () => {
    let forInactiveData = { id: activeModel?.id, body: { active: activeModel?.isActive } };
    let forActiveData = { id: activeModel?.id, body: { active: activeModel?.isActive, start_date: activeModel?.start_date, end_date: activeModel?.end_date } };
    dispatch(handleSubscriptionStatus(activeModel?.isActive ? forActiveData : forInactiveData))
      .then((res) => {
        message.success(res?.payload);
        // dispatch(GetUserCurrentSubscriptions());
        dispatch(upcomingSubscription(selectedDays?.isCustom ? { startDate: selectedDays?.startDate, endDate: selectedDays?.endDate } : { type: selectedDays?.days }));
        setActiveModel({ isShow: false, id: null, isActive: false });
        setIsCostumDate({ ...isCutomDate, isShowCalender: false, startDate: null, endDate: null });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();
    if (isEditSubscription) {
      let data = { id: newSubsData?.id, body: { ...newSubsData, plan_variety: newSubsData?.plan_variety?.trimEnd(), name: newSubsData?.name?.trimEnd() } };
      // if (newSubsData.sub_type == "paid") {
      //   setNewSubsData({ ...newSubsData, free_trial_days: "" });
      // }
      dispatch(UpdateSubscription(data))
        .then((res) => {
          message.success(res?.message || "Subscription Updated Successfully");
          dispatch(upcomingSubscription(selectedDays?.isCustom ? { startDate: selectedDays?.startDate, endDate: selectedDays?.endDate } : { type: selectedDays?.days }));
          dispatch(GetUserCurrentSubscriptions());
          handleClose();
        })
        .catch((err) => {
          message.error(err);
        });
      setNewSubsData({
        name: userData?.name,
        email: userData?.email,
        sub_title: "",
        sub_description: "",
        cost: "",
        plan_name: "monthly",
        start_date: "",
        end_date: "",
        notes: "",
        pay_method: "card",
        card_no: "",
        plan_variety: "",
        sub_type: "paid",
        free_trial_days: "",
        sub_id: null,
      });
    }
  };

  const handleEditButton = (data) => {
    setNewSubsData({
      ...newSubsData,
      name: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.name || userData?.name,
      email: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.email || userData?.email,
      active: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.active,
      sub_id: data.sub_id,
      sub_title: data?.sub_id,
      cost: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.cost,
      id: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.id,
      sub_type: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.type,
      // start_date: dayjs(usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.start_date),
      start_date: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.start_date,
      end_date: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.end_date,
      notes: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.notes,
      isPaymentMethod: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.isPaymentMethod,
      card_no: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.card_no,
      plan_variety: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.plan_variety,
      plan_name: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.plan_name,
      pay_method: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.pay_method,
      paypal_email: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.paypal_email,
    });
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
    setIsEditSubscription(true);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdown]);

  useEffect(() => {
    dispatch(GetUserCurrentSubscriptions());
    dispatch(GetALLSubscriptions());
    dispatch(upcomingSubscription({ type: 7 }));
  }, []);

  return (
    <>
      <div>
        <div className="flex items-center gap-5 py-5">
          <div className="relative">
            <div onClick={() => setDropdown(!dropdown)} ref={dropdownRef} className="w-[160px] cursor-pointer bg-white p-2 text-sm flex items-center justify-between font-medium text-black300 rounded">
              {selectedName}
              <i class="fa-solid fa-chevron-down text-black300 cursor-pointer"></i>
            </div>
            <div className={dropdown ? "cus-dropdown-design show" : "cus-dropdown-design hide"}>
              <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(7, "7 Days")}>
                7 Days
              </span>
              <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(30, "30 Days")}>
                30 Days
              </span>
              <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(60, "2 Months")}>
                2 Months
              </span>
              <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(0, "custom")}>
                Custom Date
              </span>
            </div>
          </div>
          {isCutomDate?.isShowCalender && (
            <DatePicker
              dateFormat="dd/MMM/yyyy"
              placeholderText="Select a date"
              fixedHeight
              minDate={moment().toDate()}
              startDate={isCutomDate?.startDate}
              endDate={isCutomDate?.endDate}
              onChange={handleDateChange}
              selectsRange
              className="w-[240px] cursor-pointer bg-white p-2 text-sm placeholder:text-sm flex items-center justify-between font-medium text-black300 rounded"
              onKeyDown={(e) => e.preventDefault()}
            />
          )}
        </div>
      </div>
      <div className="w-full bg-white rounded-xl p-4 overflow-auto">
        {upcomingSubsLoader ? (
          <>
            <FullPageLoader />
            <Skeleton height={30} count={10} />
          </>
        ) : upcomingSubsData?.length > 0 ? (
          <table className="w-full border-collapse ">
            <thead>
              <tr className="border-solid border-b border-borderColor1">
                <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2"></th>
                <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Service Name</th>
                <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Price</th>
                <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Renewal Date</th>
                {/* <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Total Amount</th> */}
                <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Start Date</th>
                <th className="text-base mobile:text-sm mobile:p-2.5 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {upcomingSubsData.map((item, index) => {
                return (
                  <tr className=" border-solid border-b border-borderColor1 hover:bg-inputBackground">
                    <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5">
                      <img className="w-8 h-8 block object-cover shadow-4xl" src={item?.sub_logo} />
                    </td>
                    <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5">{item?.sub_name}</td>
                    {item?.type === "free" ? (
                      <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5">Free plan</td>
                    ) : (
                      <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5">
                        {usersData?.currency ?? "$"}
                        {item?.price}/{item?.plan_name}
                      </td>
                    )}
                    <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5 mobile:whitespace-nowrap">
                      {item?.type === "free" ? "-" : item?.renewal_date ? moment(item.renewal_date).format("Do MMM YYYY") : "-"}
                      {/* <p className="m-0 text-gray650 text-xs ">(7 Days left)</p> */}
                    </td>
                    {/* <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5">${item?.totalCost ? item?.totalCost?.toFixed(2) : 0.00}</td> */}
                    <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5 mobile:whitespace-nowrap">{item?.start_date ? moment(item.start_date).format("Do MMM YYYY") : "-"} </td>
                    <td className="text-sm font-normal text-left text-black400 p-2 mobile:p-2.5">
                      <div className="flex items-center gap-3">
                        <button className="bg-primary text-black200 text-sm py-1 font-medium px-5 rounded border-none cursor-pointer" onClick={() => setActiveModel({ isShow: true, id: item?.id, isActive: false })}>
                          Deactivate
                        </button>
                        <div onClick={() => handleEditButton(item)}>
                          <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_1079_3756)">
                              <path
                                d="M15.216 0.783999C14.7065 0.2971 14.0288 0.0253906 13.324 0.0253906C12.6192 0.0253906 11.9416 0.2971 11.432 0.783999L1.07401 11.142C0.732617 11.4815 0.46192 11.8853 0.277573 12.3301C0.0932258 12.7749 -0.00111372 13.2519 9.9204e-06 13.7333V15C9.9204e-06 15.2652 0.105367 15.5196 0.292903 15.7071C0.48044 15.8946 0.734793 16 1.00001 16H2.26668C2.74838 16.0013 3.22556 15.907 3.67059 15.7227C4.11562 15.5383 4.51967 15.2676 4.85934 14.926L15.216 4.568C15.7171 4.06582 15.9985 3.3854 15.9985 2.676C15.9985 1.9666 15.7171 1.28617 15.216 0.783999ZM3.44401 13.512C3.13093 13.823 2.708 13.9984 2.26668 14H2.00001V13.7333C2.00138 13.2916 2.1767 12.8681 2.48801 12.5547L10.2 4.84467L11.1553 5.8L3.44401 13.512ZM13.8 3.154L12.5693 4.38667L11.6133 3.43067L12.8467 2.2C12.9753 2.07705 13.1464 2.00844 13.3243 2.00844C13.5023 2.00844 13.6734 2.07705 13.802 2.2C13.9277 2.32704 13.9981 2.49867 13.9977 2.67741C13.9974 2.85615 13.9263 3.02749 13.8 3.154Z"
                                fill="#5A6ACF"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1079_3756">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center  p-3 rounded-[20px] w-full">
              <img src={NoDataFound} alt="No Data Found" width={200} height={200} />
              <p className="text-xl font-medium text-black">No subscription found</p>
              <p>There is no any upcoming subscripitons yet</p>
            </div>
          </>
        )}
      </div>
      {activeModel?.isShow && <SubscriptionStatusModel activeModel={activeModel} setActiveModel={setActiveModel} handleChangeSubscriptionStatus={handleChangeSubscriptionStatus} />}
      {open && (
        <SubscriptiondetailsModal
          handleClose={handleClose}
          setNewSubsData={setNewSubsData}
          newSubsData={newSubsData}
          handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          error={error}
          setError={setError}
          allSubscriptions={allSubscriptions}
          setIsEditSubscription={isEditSubscription}
        />
      )}
      {updateSubscLoader && <FullPageLoader />}
    </>
  );
};

export default UpcommingTable;
