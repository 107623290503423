import React, { useEffect, useState } from "react";
import DataTable from "../../common/commonComponents/Datatable/Datatable";
import styles from "./recurring.module.css";
import commonstyles from "../../common/commonStyling/common.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoRecurringFound from "../../../assets/images/no-recurring.png";
import { Link, Router } from "react-router-dom";

import { Button } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {  recurringSubscriptionsData } from "../../../redux/features/subscriptionSlice";
import CalenderView from "../../common/commonComponents/Calender";
import { useNavigate } from "react-router-dom";
import UpcommingTable from "../UpcommingTable";
import AllAppsTable from "../AllAppsTable";
import CalenderViewDemo from "../../common/commonComponents/CalenderDemo";
// import { getUser, useAuth } from "../../../context/authContext";

import DashbordFeatures from "../../commonComponents/UnsubscribedFeatures";
import FeatureCard from "../../features/FeatureCard";

function Recurring() {
  const [activeTab, setActiveTab] = useState("upcoming");

  const navigate = useNavigate();
  const [value, onChange] = useState(new Date());
  const dispatch = useDispatch();
  // const { recurringDataUpcoming, recurringDataAll } = useSelector((state) => ({
  //   ...state.subscription,
  // }));
  const userData = JSON.parse(localStorage.getItem("usersData"));

  // const { isAuthenticate, isUserSubscribed } = useAuth();
  // const [isUserSubscrib, setIsUserSubscrib] = useState(isUserSubscribed);

  // console.log(value, "value");
  // const onChange = () => {};
  const tabs = [
    {
      tabName: "Upcoming",
      tabValue: "upcoming",
    },
    // {
    //   tabName: "All Apps",
    //   tabValue: "all",
    // },
    {
      tabName: "Calender",
      tabValue: "calender",
    },
  ];
  const handleTabClick = (activetab) => {
    setActiveTab(activetab);
  };

  const columns = [
    {
      name: "sub_logo",
      label: "Logo",
      // options: { filter: true, sort: true },
    },
    { name: "sub_name", label: "Name", options: { filter: true, sort: true } },
    { name: "cost", label: "Price", options: { filter: true, sort: true } },
    {
      name: "RenewalDate",
      label: "Renewal Date",
      options: { filter: true, sort: true },
    },
    // {
    //   name: "Lastvisited",
    //   label: "Last visited",
    //   options: { filter: true, sort: true },
    // },
    {
      name: "DateCreated",
      label: "Date Created",
      options: { filter: true, sort: true },
    },
    { name: "action", label: "Action", options: { filter: true, sort: true } },
  ];


  // useEffect(() => {
  //   async function fetchData() {
  //     const authData = await getUser();
  //     setIsUserSubscrib(authData?.isUserSubscribed);
  //   }
  //   fetchData();
  // }, []);

  const handleManage = (row) => {
    console.log(row, "row in handleManage");
    navigate("/dashboard/add-new", { state: { ...row, openModal: true } });
  };
  // console.log(recurringDataAll, recurringDataUpcoming, "12345");
  return (
    <>
      {!userData?.isUserSubscribed ? (
        <div className="flex h-screen items-center justify-center">
          <div className="unsubscribedContainerWrapper">
            <div className="unsubscribedContainer">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                }}
              >
                <img src="/assets/lockgif.gif" alt="lock" className="lockimage" style={{ width: "140px", height: "140px" }} />
                <h6 className="unsubscribedHeading">
                  This Feature is <br /> locked in Free Plan
                </h6>
                <p className="unsubscribedpara">
                  Please, Upgrade your plan to <br /> enjoy full benefits
                </p>
              </div>
              <FeatureCard feature={DashbordFeatures} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <h3 className="text-xl text-black300 mobile:text-sm font-medium mb-5">Recurring Subscriptions</h3>
          <div className="flex items-center gap-9 pb-2 border-b border-solid border-borderColor1">
            {tabs.map((tab, id) => (
              <div style={{ cursor: "pointer" }} key={id} onClick={() => handleTabClick(tab.tabValue)}>
                <span className={`text-sm custom-tab ${activeTab === tab.tabValue ? "active-custom-tab" : ""} relative font-medium text-black300`}>{tab.tabName}</span>
              </div>
            ))}
          </div>
          {activeTab === "upcoming" ? <UpcommingTable /> : activeTab === "all" ? <AllAppsTable /> : <CalenderViewDemo fullSizeCalendar={"fullSizeCalendar"} />}
          {/* {activeTab === "upcoming" ? <UpcommingTable /> : activeTab === "all" ? <AllAppsTable /> : <CalenderView fullSizeCalendar={"fullSizeCalendar"} />} */}

          {/* <div style={{ padding: "0px 20px" }}>
        {activeTab === "upcoming" || activeTab === "all" ? (
          <div>
            {(activeTab === "all" && recurringDataAll?.length > 0) || (activeTab === "upcoming" && recurringDataUpcoming?.length > 0) ? (
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "18px",
                  background: "#FFF",
                  padding: "10px 20px",
                }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell key={index.toString()} align="center">
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeTab === "all"
                      ? recurringDataAll?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              borderBottom: "1px solid #E5E5E5",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <img src={row.sub_logo} alt="subscription" width={35} height={35} />
                            </TableCell>
                         
                            <TableCell align="center">{row.sub_name}</TableCell>
                            <TableCell align="center">{row.price}</TableCell>
                            <TableCell align="center">{row.renewal_date}</TableCell>
                   
                            <TableCell align="center">{row.days_left}</TableCell>
                            <TableCell
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "14px",
                              }}
                            >
                              <Button className={`${styles.action_btn} ${styles.para_14}`} variant="contained" onClick={() => handleManage(row)}>
                                {"Manage"}
                              </Button>
                            
                            </TableCell>
                          </TableRow>
                        ))
                      : recurringDataUpcoming?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              borderBottom: "1px solid #E5E5E5",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <img src={row.sub_logo} alt="subscription" width={35} height={35} />
                            </TableCell>
                            <TableCell align="center">{row.sub_name}</TableCell>
                            <TableCell align="center">{row.price}</TableCell>
                            <TableCell align="center">{row.renewal_date}</TableCell>
                            <TableCell align="center">{row.last_visited}</TableCell>
                            <TableCell align="center">{row.days_left}</TableCell>
                            <TableCell
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "14px",
                              }}
                            >
                              <Button className={`${styles.action_btn} ${styles.para_14}`} variant="contained" onClick={() => handleManage(row)}>
                                {"Manage"}
                              </Button>
                            
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center mobile:gap-2 mobile:grid mobile:grid-cols-2 p-3 rounded-[20px] w-full">
                <img src={NoRecurringFound} alt="NoRecurringFound" width={200} height={200} />
                <p className="text-xl font-medium text-black">No subscription found</p>
                <p>You have not purchased any subscripitons yet</p>
              </div>
             
            )}
          </div>
        ) : (
          <div className={""}>
            <CalenderView fullSizeCalendar={"fullSizeCalendar"} />
          </div>
        )}
      </div> */}
        </>
      )}
    </>
  );
}

export default Recurring;
