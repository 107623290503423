import React, { useEffect, useRef, useState } from "react";
import styles from "./subscription.module.css";
import SubscriprionCards from "../../common/commonComponents/SubscriptionCards";
import { useDispatch, useSelector } from "react-redux";
import { AddSubscription, GetAllCategory, GetALLSubscriptions, GetBrowseAllData } from "../../../redux/features/subscriptionSlice";
import { currentSubscriptionList } from "../../../redux/features/subscriptionSlice";
import SubscriptionSlider from "../../../modules/adminDashboard/currentSubscription/subscriptionSlider";
import BrowserAlladdinCards from "../BrowserAlladdinCards";
import AddNewSubsCard from "../../AddNewSubsCard";
import NoDataFound from "../../../assets/images/alladdindb-no-data.png";
import ModalComponent from "../../addnew/ModalComponent";
import { getUser, useAuth } from "../../../context/authContext";
import { message } from "antd";
import { useLocation } from "react-router";
import SubscriptiondetailsModal from "../../subscriptiondetailsModal";
import FullPageLoader from "../../common/loaders/FullPageLoader";

import DashbordFeatures from "../../commonComponents/UnsubscribedFeatures";
import FeatureCard from "../../features/FeatureCard";


function SubscriptionList() {
  const [open, setOpen] = useState(false);
  let { state } = useLocation();
  const { userData } = useAuth();
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const { allSubscriptions, alladdinCategory } = useSelector((state) => state.subscription);
  const [selectedName, setSelectedName] = useState("All Category");
  const { isAuthenticate, isUserSubscribed } = useAuth();
  const usersData = JSON.parse(localStorage.getItem("usersData"));
  const [isUserSubscrib, setIsUserSubscrib] = useState(isUserSubscribed)
  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: Number(allSubscriptions?.[0]?.sub_id),
    sub_description: "",
    cost: state && state?.price ? state?.price : state?.cost ? state?.cost : "",
    isPaymentMethod: 0,
    plan_name: "monthly",
    start_date: "",
    end_date: "",
    notes: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: "",
  });
  const [error, setError] = useState({});

  const { borwseAlladdinData, borwseAlladdinLoader } = useSelector((state) => state.subscription);

  const handleOpenAddModel = (data) => {
    setOpen(true);
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: Number(data?.sub_id),
      isPaymentMethod: data?.isPaymentMethod ?? 0,
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      end_date: "",
      notes: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
    });
    setError({});
  };

  const handleClose = () => {
    setOpen(false);
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: Number(allSubscriptions?.[0]?.sub_id),
      isPaymentMethod: 0,
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      end_date: "",
      notes: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
    });
    setError({});
  };

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();
    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }

    dispatch(AddSubscription({ ...newSubsData, plan_variety: newSubsData?.plan_variety?.trimEnd(), name: newSubsData?.name?.trimEnd() }))
      .then((res) => {
        message.success(res.payload);
        // dispatch(GetALLSubscriptions());r
      })
      .catch((err) => message.error(err));
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      isPaymentMethod: 0,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      end_date: "",
      notes: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
    });
    handleClose();
  };

  const handleChangeFilter = (data) => {
    setSelectedName(data?.name ?? "All Category");
    dispatch(GetBrowseAllData(data?.id));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdown]);

  useEffect(() => {
    dispatch(GetALLSubscriptions());
    dispatch(GetBrowseAllData());
    dispatch(GetAllCategory());
    async function fetchData() {
      const authData = await getUser();
      setIsUserSubscrib(authData?.isUserSubscribed)
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  return (
    !usersData?.isUserSubscribed ? (
      <div className="flex h-screen items-center justify-center">
        <div className="unsubscribedContainerWrapper">
          <div className="unsubscribedContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
              }}
            >
              <img src="/assets/lockgif.gif" alt="lock" className="lockimage" style={{ width: "140px", height: "140px" }} />
              <h6 className="unsubscribedHeading">
                This Feature is <br /> locked in Free Plan
              </h6>
              <p className="unsubscribedpara">
                Please, Upgrade your plan to <br /> enjoy full benefits
              </p>
            </div>
            <FeatureCard feature={DashbordFeatures} />
          </div>
        </div>
      </div>
    ) : (
    <>
      <div className="flex justify-between pb-5 border-b border-solid border-borderColor1 mobile:pb-2.5">
        <h3 className="text-[22px] leading-normal text-black300 font-medium mobile:text-base">Subscriptions</h3>
      </div>
      <div className="relative mt-5 w-[160px] mobile:mt-2.5">
          <div onClick={() => setDropdown(!dropdown)} ref={dropdownRef} className="w-full cursor-pointer bg-white p-2 text-sm flex items-center justify-between font-medium text-black300 rounded">
            {selectedName}
            <i class="fa-solid fa-chevron-down text-black300 cursor-pointer"></i>
          </div>
          <div className={dropdown ? "cus-dropdown-design show" : "cus-dropdown-design hide"}>
            <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter("All Category")}>
              All Category
            </span>
            {alladdinCategory?.map((items, index) => {
              return (
                <span onClick={() => handleChangeFilter(items)} className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover">
                  {items.name}
                </span>
              );
            })}

            {/* <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover">Last 2 month</span>
            <span className="block text-sm p-2 font-medium text-black300  cursor-pointer transition ease-in-out hover:bg-bgHover ">Last 4 month</span> */}
          </div>
        </div>

      {borwseAlladdinLoader ? (
        <>
        <FullPageLoader />
        <div className="flex flex-wrap pt-5 tab:grid tab:grid-cols-2 gap-5 mobile:grid !mobile:grid-cols-1">
          {borwseAlladdinData.map((item, index) => {
            return <AddNewSubsCard item={item} index={index} handleOpenAddModel={handleOpenAddModel} />;
          })}
        </div>
        </>
      ) : borwseAlladdinData?.length > 0 ? (
        <div className="flex flex-wrap pt-5 tab:grid-cols-2 gap-5 mobile:grid !mobile:grid-cols-1">
          {borwseAlladdinData.map((item, index) => {
            return <AddNewSubsCard item={item} index={index} handleOpenAddModel={handleOpenAddModel} />;
          })}
        </div>
      ) : (
        <div className=" bg-[#fff] flex pt-5 flex-col gap-2 items-center justify-center mobile:gap-2 mobile:grid mobile:grid-cols-2 p-3 rounded-[20px] w-full">
          <img src={NoDataFound} alt="No Data Found" width={200} height={200} />
          <p className="text-xl font-medium text-black">No subscription found</p>
          <p>There is no any subscripitons yet</p>
        </div>
      )}
      {/* <ModalComponent
        open={open}
        userData={userData}
        setOpen={setOpen}
        handleClose={handleClose}
        handleSubsDetails={handleSubsDetails}
        setNewSubsData={setNewSubsData}
        newSubsData={newSubsData}
        handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
        error={error}
        setError={setError}
        allSubscriptions={allSubscriptions}
      /> */}

      {open && (
        <SubscriptiondetailsModal
          open={open}
          userData={userData}
          setOpen={setOpen}
          handleClose={handleClose}
          setNewSubsData={setNewSubsData}
          newSubsData={newSubsData}
          handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          error={error}
          setError={setError}
          allSubscriptions={allSubscriptions}
          setIsEditSubscription={false}
        />
      )}

      {/* {borwseAlladdinData?.map((item, id) => (
        <>
          <h2 className="text-[25px]" style={{ margin: "8px", fontWeight: "500" }}>
            {item?.name}
          </h2>
          <div className={styles.subscriptionContainer}>
            {item?.subscriptions?.map((itemData, id) => (
              <div className={styles.cardWrapper} key={id}>
                <BrowserAlladdinCards item={itemData} />
              </div>
            ))}
          </div>
        </>
      ))} */}
    </>
    )
  );
}

export default SubscriptionList;
