// "use strict";
Object.defineProperty(exports, "__esModule", {
  value: true,
});
if (process.env.NODE_ENV == "development") {
  exports.default = {
    websiteURL: "https://localhost:3000/",
    // baseURL: "https://alladdinx.com/api",
    // imageURL: "https://cdn.alladdinx.com/",
    // baseURL: "https://87d6-2405-201-200d-1932-1c03-f1c6-2a1c-f82.ngrok-free.app/api",
    // baseURL: "https://259s7s89-5005.inc1.devtunnels.ms/api",
    baseURL: "https://dev.alladdinx.com/api",
    backendURL: "http://localhost:5800/api",
  };
} else {
    exports.default = {
      websiteURL: "https://alladdinx.com/",
      // baseURL: "https://alladdinx.com/api",
      imageURL: "https://cdn.alladdinx.com/",
      // baseURL: "https://87d6-2405-201-200d-1932-1c03-f1c6-2a1c-f82.ngrok-free.app/api",
      // baseURL: "https://259s7s89-5005.inc1.devtunnels.ms/api",
      baseURL: "https://dev.alladdinx.com/api",
      backendURL: "https://alladdinx.com/api",
    };
}
