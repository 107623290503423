import React, { useState } from "react";
import ArrowIcon from "../../../svg/ArrowIcon";
import WhiteTrueIcon from "../../../svg/WhiteTrueIcon";

const FaqSection = () => {
  const [toogle, setToogle] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false
  });

  return (
    <div className="pb-100 mobile:pb-60">
      <div className="max-w-1160 mx-auto px-5 mobile:px-30">
        <h2 className="text-[40px] mobile:text-[26px] mobile:leading-normal text-black300 font-semibold leading-[48px] mobile:mb-10 mb-10 text-center">Frequently Asked Questions</h2>
        <div className={toogle?.faq1 ? "main-border main-border-change mb-6" : "main-border mb-6"}>
          <div className="faq-header">
            <div className="flex items-center justify-between">
              <p className="text-xl mobile:text-lg text-black200 font-semibold">1. Can all subscriptions be managed through Alladdin?</p>
              <div onClick={() => setToogle({ faq1: !toogle?.faq1 })}>
                <ArrowIcon />
              </div>
            </div>
          </div>
          <div className={toogle?.faq1 ? "faq-body show" : "faq-body hide"}>
            <div className="p-5 pl-12 mobile:pl-5 mobile:pt-0">
              <p className="text-lg font-normal leading-normal text-black200 ">Yes, Alladdin allows users to manage all subscriptions from within the platform.</p>
            </div>
          </div>
        </div>
        <div className={toogle?.faq2 ? "main-border main-border-change mb-6" : "main-border mb-6"}>
          <div className="faq-header">
            <div className="flex items-center justify-between">
              <p className="text-xl mobile:text-lg text-black200 font-semibold">2. Does Alladdin assist with auto-renewing subscriptions through the website?</p>
              <div onClick={() => setToogle({ faq2: !toogle?.faq2 })}>
                <ArrowIcon />
              </div>
            </div>
          </div>
          <div className={toogle?.faq2 ? "faq-body show" : "faq-body hide"}>
            <div className="p-5 pl-12 mobile:pl-5 mobile:pt-0">
              <p className="text-lg font-normal leading-normal text-black200 ">Alladdin makes auto-renewing and managing subscriptions straightforward. Timely reminders are sent prior to renewal dates.</p>
            </div>
          </div>
        </div>
        <div className={toogle?.faq3 ? "main-border main-border-change mb-6" : "main-border mb-6"}>
          <div className="faq-header">
            <div className="flex items-center justify-between">
              <p className="text-xl mobile:text-lg text-black200 font-semibold">3. Does Alladdin offer a free trial?</p>
              <div onClick={() => setToogle({ faq3: !toogle?.faq3 })}>
                <ArrowIcon />
              </div>
            </div>
          </div>
          <div className={toogle?.faq3 ? "faq-body show" : "faq-body hide"}>
            <div className="p-5 pl-12 mobile:pl-5 mobile:pt-0">
              <p className="text-lg font-normal leading-normal text-black200 ">Alladdin offers a seven-day free trial. Users can choose to subscribe for ongoing access if they find the platform useful during the trial period.</p>
            </div>
          </div>
        </div>
        <div className={toogle?.faq4 ? "main-border main-border-change mb-6" : "main-border mb-6"}>
          <div className="faq-header">
            <div className="flex items-center justify-between">
              <p className="text-xl mobile:text-lg text-black200 font-semibold">4. What benefits does an Alladdin subscription provide?</p>
              <div onClick={() => setToogle({ faq4: !toogle?.faq4 })}>
                <ArrowIcon />
              </div>
            </div>
          </div>
          <div className={toogle?.faq4 ? "faq-body show" : "faq-body hide"}>
            <div className="p-5 pl-12 mobile:pl-5 mobile:pt-0">
              <p className="text-lg font-normal leading-normal text-black200 ">An Alladdin subscription consolidates subscription data, usage tracking, and management tools in a centralized location for ease of use.</p>
            </div>
          </div>
        </div>
        <div className={toogle?.faq5 ? "main-border main-border-change mb-6" : "main-border mb-6"}>
          <div className="faq-header">
            <div className="flex items-center justify-between">
              <p className="text-xl mobile:text-lg text-black200 font-semibold">5. How can an Alladdin subscription be canceled?</p>
              <div onClick={() => setToogle({ faq5: !toogle?.faq5 })}>
                <ArrowIcon />
              </div>
            </div>
          </div>
          <div className={toogle?.faq5 ? "faq-body show" : "faq-body hide"}>
            <div className="p-5 pl-12 mobile:pl-5 mobile:pt-0">
              <p className="text-lg font-normal leading-normal text-black200 ">Subscriptions can be canceled at any time by contacting Alladdin's customer support number or email address.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
