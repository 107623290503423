import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { GetALLSubscriptions, getCalenderData, GetUserCurrentSubscriptions, UpdateSubscription } from "../../../../redux/features/subscriptionSlice";
import { Tooltip } from "react-tooltip";
import { Spin, Avatar, List, message } from "antd";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerDemo.scss";
import moment from "moment";
import FullPageLoader from "../../loaders/FullPageLoader";
import SubscriptiondetailsModal from "../../../subscriptiondetailsModal";
import { useAuth } from "../../../../context/authContext";

function CalenderViewDemo({ fullSizeCalendar, inlineCalendar }) {
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const usersData = JSON.parse(localStorage.getItem('usersData'));
  const { calenderData, calenderDataLoader, usersCurrentSub, allSubscriptions, updateSubscLoader } = useSelector((state) => state.subscription);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [subscriptionColors, setSubscriptionColors] = useState({});

  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});

  const [selectedDates, setSelectedDates] = useState([new Date("2023-10-10"), new Date("2023-10-15"), new Date("2023-11-05")]);
  const [currentDates, setCurrentDates] = useState()
  const [displayedMonth, setDisplayedMonth] = useState(new Date());
  // const [highlightDates, setHighlightDates] = useState([]);

  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: "",
    sub_description: "",
    cost: "",
    plan_name: "monthly",
    start_date: "",
    end_date: "",
    notes: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: "",
  });

  const isDateHighlighted = (date) => selectedDates.some((selectedDate) => selectedDate.getDate() === date.getDate() && selectedDate.getMonth() === date.getMonth() && selectedDate.getFullYear() === date.getFullYear());

  const getSubscriptionsByDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return calenderData.filter((sub) => moment(sub.end_date).format("YYYY-MM-DD") === formattedDate);
  };

  const handleChangeMonth = (date) => {
    // setDisplayedMonth(date);
    let data = {
      calender_month: new Date(date).getMonth() + 1,
      calender_year: new Date(date).getFullYear(),
    };
    setCurrentDates(data);
    dispatch(getCalenderData(data)).then((res) => {
      let dates = [];
      if (Array.isArray(res?.payload?.monthlyPlan)) {
        res?.payload?.monthlyPlan?.map((item) => {
          dates.push(new Date(item.end_date));
        });
      }
      if (Array.isArray(res?.payload?.weeklyPlan)) {
        res?.payload?.weeklyPlan?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
        // const highlightData = (res?.payload ?? [])?.map((sub) => ({
        //   date: moment(sub.end_date),
        //   sub_name: sub.sub_name,
        //   cost: sub.cost,
        // }));
        // setHighlightDates(highlightData);
      }
    });
  };

  const handleSubsDetails = (e) => {
    if (e.target.name === "email") {
      if (e.target.value.trim() !== "") {
        setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
      } else {
        setNewSubsData({ ...newSubsData, [e.target.name]: "" });
        setError({ ...error, [e.target.name]: "Please enter email" });
      }
    } else {
      setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value?.trimStart() });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setError({});
  };

  const handleClickSubscription = (data) => {
    setNewSubsData({
      ...newSubsData,
      name: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.name || userData?.name,
      email: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.email || userData?.email,
      sub_id: data.sub_id,
      sub_title: data?.sub_id,
      active: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.active,
      cost: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.cost,
      id: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.id,
      sub_type: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.type,
      free_trial_days: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.free_trial_days,
      // start_date: dayjs(usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.start_date),
      start_date: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.start_date,
      end_date: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.end_date,
      notes: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.notes,
      isPaymentMethod: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.isPaymentMethod,
      card_no: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.card_no,
      plan_variety: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.plan_variety,
      plan_name: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.plan_name,
      pay_method: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.pay_method,
      paypal_email: usersCurrentSub?.find((sub) => sub.sub_id === data.sub_id)?.paypal_email,
    });
    handleOpen();
  };

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();
    let data = { id: newSubsData?.id, body: { ...newSubsData, plan_variety: newSubsData?.plan_variety?.trimEnd(), name: newSubsData?.name?.trimEnd() } };
    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }
    dispatch(UpdateSubscription(data))
      .then((res) => {
        message.success(res?.message || "Subscription Updated Successfully");
        dispatch(GetUserCurrentSubscriptions());
        dispatch(getCalenderData(currentDates)).then((res) => {
          let dates = [];
          if (Array.isArray(res?.payload?.monthlyPlan)) {
            res?.payload?.monthlyPlan?.map((item) => {
              dates.push(new Date(item.end_date));
            });
          }
          if (Array.isArray(res?.payload?.weeklyPlan)) {
            res?.payload?.weeklyPlan?.map((item) => {
              dates.push(new Date(item.end_date));
            });
            setSelectedDates(dates);
          }
          // const highlightData = res?.payload?.map((sub) => ({
          //   date: moment(sub.end_date),
          //   sub_name: sub.sub_name,
          //   cost: sub.cost,
          // }));
          // setHighlightDates(highlightData);
        });

        handleClose();
      })
      .catch((err) => {
        message.error(err);
      });
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      end_date: "",
      notes: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
      sub_id: null,
    });
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    let data = {
      calender_month: new Date().getMonth() + 1,
      calender_year: new Date().getFullYear(),
    };
    setCurrentDates(data)
    dispatch(getCalenderData(data)).then((res) => {
      let dates = [];
      if (Array.isArray(res?.payload?.monthlyPlan)) {
        res?.payload?.monthlyPlan?.map((item) => {
          dates.push(new Date(item.end_date));
        });
      }
      if (Array.isArray(res?.payload?.weeklyPlan)) {
        res?.payload?.weeklyPlan?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
      }
      // const highlightData = res?.payload?.map((sub) => ({
      //   date: moment(sub.end_date),
      //   sub_name: sub.sub_name,
      //   cost: sub.cost,
      // }));
      // setHighlightDates(highlightData);
    });
    dispatch(GetUserCurrentSubscriptions());
    dispatch(GetALLSubscriptions());
  }, []);

  useEffect(() => {
    const colors = {};
    calenderData.forEach((sub) => {
      if (!colors[sub.sub_name]) {
        colors[sub.sub_name] = getRandomColor();
      }
    });
    setSubscriptionColors(colors);
  }, [calenderData]);

  const renderDayContents = (day, date) => {
    const subsForDay = getSubscriptionsByDate(date);

    return (
      <div className="calendar-day">
        <span className="day-number">{day}</span>
        <div className="subscriptions-container">
          {subsForDay.length > 0 &&
            subsForDay.map((sub, idx) => (
              <div key={idx} className="subscription-badge" onClick={() => handleClickSubscription(sub)} style={{ backgroundColor: subscriptionColors[sub.sub_name] || getRandomColor() }}>
                {sub.sub_name} - {usersData?.currency ?? "$"}{sub.cost}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {fullSizeCalendar ? (
        <div className="calendar-container">
          <DatePicker inline minDate={new Date()} onMonthChange={(date) => handleChangeMonth(date)} selected={selectedDate} onChange={(date) => setSelectedDate(date)} renderDayContents={renderDayContents} />
        </div>
      ) : (
        <DatePicker
          highlightDates={selectedDates}
          calendarClassName={`${fullSizeCalendar} ${inlineCalendar}`}
          inline
          isClearable
          minDate={new Date()}
          onMonthChange={(date) => handleChangeMonth(date)}
          dayClassName={(date) => (isDateHighlighted(date) ? "highlighted-date" : undefined)}
          disabledKeyboardNavigation
          shouldCloseOnSelect={false}
        />
      )}
      {(calenderDataLoader || updateSubscLoader) && <FullPageLoader />}
      {open && (
        <SubscriptiondetailsModal
          open={open}
          userData={userData}
          setOpen={setOpen}
          handleClose={handleClose}
          handleSubsDetails={handleSubsDetails}
          setNewSubsData={setNewSubsData}
          newSubsData={newSubsData}
          handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          error={error}
          setError={setError}
          allSubscriptions={allSubscriptions}
          setIsEditSubscription={true}
        />
      )}
    </>
  );
}

export default CalenderViewDemo;

{
  /* {selectedDates.map((date, index) => (
        <a
          key={index}
          data-tooltip-id={`tooltip-${index}`}
          data-tooltip-content={events[date.toISOString().split("T")[0]]}
          data-tooltip-place="top"
          className="selected-date"
        >
          {date.getDate()}
        </a>
      ))}
      {selectedDates.map((date, index) => (
        <Tooltip
          key={index}
          id={`tooltip-${index}`}
          effect="solid"
          place="top"
        />
      ))} */
}
